<template>
  <div>
    <b-container class="py-3 py-md-5">
      <b-row cols=" py-0 py-md-4">
        <div class="col-12 col-md-6 col-lg-6">
          <a href="#"><img src="../../assets/logo.png" /></a>
          <h3 class="font-weight-bold m-0">
            Goed nieuws! Jouw woning
            <span class="text-secondary">
              {{ this.address?.street }} {{ this.address?.streetNumber }}</span
            >
            in
            <span class="text-secondary">{{ this.address?.settlement }} </span>
            is geschikt voor zonnepanelen.
          </h3>
          <div class="my-2 my-md-4">
            <div>
              <GmapMap
                :options="{
                  zoomControl: false,
                  fullscreenControl: false,
                  mapTypeControl: false,
                  scaleControl: false,
                  streetViewControl: false,
                  rotateControl: true,
                  disableDefaultUi: false,
                }"
                :center="{ lat: this.lat, lng: this.lng }"
                :zoom="16"
                map-type-id="terrain"
                style="width: 100%; height: 200px"
              >
                <GmapMarker
                  :position="{
                    lat: this.lat,
                    lng: this.lng,
                  }"
                  :clickable="true"
                  :draggable="true"
                />
              </GmapMap>
            </div>
          </div>
          <CheckList />
          <!-- <h3 class="text-center">Bespaar tot 30% in maar 2 minuten!</h3> -->
        </div>
        <!--  -->
        <div class="col-12 col-md-6">
          <div
            class="bg-white shadow p-3 p-md-4 banner__form text-center d-flex justify-content-center"
          >
            <h4 class="align-self-center">Bedankt!</h4>
            <img
              v-if="profId"
              referrerpolicy="no-referrer-when-downgrade"
              :src="`https://republish.verbeterthuis.nl/m/5285/f0c50cffb7a4/?event=6188&unique_conversion_id=${profId}`"
              style="width: 1px; height: 1px; border: 0px"
            />
          </div>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CheckList from "../../components/Airco/CheckList.vue";
export default {
  name: "Bedankt",
  computed: mapGetters(["lng", "lat", "first_form", "profId", "address"]),
  components: { CheckList },
};
</script>

<style lang="scss" scoped>
.text-secondary {
  color: #74a200 !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.banner {
  &__form {
    border-radius: 8px !important;
    border-left: 2px solid #eee !important;
    border-color: #74a200 !important;
    height: 590px;
  }
}
</style>
