var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-container',{staticClass:"py-3 py-md-5"},[_c('b-row',{attrs:{"cols":" py-0 py-md-4"}},[_c('div',{staticClass:"col-12 col-md-6 col-lg-6"},[_c('a',{attrs:{"href":"#"}},[_c('img',{attrs:{"src":require("../../assets/logo.png")}})]),_c('h3',{staticClass:"font-weight-bold m-0"},[_vm._v(" Goed nieuws! Jouw woning "),_c('span',{staticClass:"text-secondary"},[_vm._v(" "+_vm._s(this.address?.street)+" "+_vm._s(this.address?.streetNumber))]),_vm._v(" in "),_c('span',{staticClass:"text-secondary"},[_vm._v(_vm._s(this.address?.settlement)+" ")]),_vm._v(" is geschikt voor zonnepanelen. ")]),_c('div',{staticClass:"my-2 my-md-4"},[_c('div',[_c('GmapMap',{staticStyle:{"width":"100%","height":"200px"},attrs:{"options":{
                zoomControl: false,
                fullscreenControl: false,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: true,
                disableDefaultUi: false,
              },"center":{ lat: this.lat, lng: this.lng },"zoom":16,"map-type-id":"terrain"}},[_c('GmapMarker',{attrs:{"position":{
                  lat: this.lat,
                  lng: this.lng,
                },"clickable":true,"draggable":true}})],1)],1)]),_c('CheckList')],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"bg-white shadow p-3 p-md-4 banner__form text-center d-flex justify-content-center"},[_c('h4',{staticClass:"align-self-center"},[_vm._v("Bedankt!")]),(_vm.profId)?_c('img',{staticStyle:{"width":"1px","height":"1px","border":"0px"},attrs:{"referrerpolicy":"no-referrer-when-downgrade","src":`https://republish.verbeterthuis.nl/m/5285/f0c50cffb7a4/?event=6188&unique_conversion_id=${_vm.profId}`}}):_vm._e()])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }